.rdrDateRangePickerWrapper {
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #eff2f7;
}

.rdrInRange {
  background: rgba(30, 150, 242, 0.1) !important;
  color: var(--black80) !important;
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: var(--black80) !important;
}

.rdrEndEdge,
.rdrStartEdge {
  color: var(--primary_default) !important;
}
.rdrDayToday .rdrDayNumber span:after {
  display: none !important;
}

.dark-theme .rdrDateRangePickerWrapper {
  background-color: var(--black110);
  border: 1px solid var(--black80);
}
.dark-theme .rdrDefinedRangesWrapper {
  background-color: var(--black110);
  color: var(--black50);
  border-color: var(--black80);
}
.dark-theme .rdrStaticRange {
  background-color: transparent;
  border-bottom: 1px solid var(--blue_grey_80);
  color: var(--black50);
}
.dark-theme .rdrStaticRange:hover span {
  background-color: var(--black130);
}
.dark-theme .rdrInputRangeInput {
  background-color: transparent;
  border: 1px solid var(--black70);
}
.dark-theme .rdrMonthAndYearWrapper {
  background-color: var(--black110);
}
.dark-theme .rdrMonth {
  background-color: var(--black110);
}
.dark-theme .rdrDayNumber span {
  color: white;
}
.dark-theme .rdrDayDisabled {
  background-color: var(--black110);
  color: var(--black50);
}
.dark-theme .rdrWeekDay,
.dark-theme .rdrMonthName {
  color: var(--black70);
}
.dark-theme .rdrMonthAndYearPickers select {
  color: var(--black50);
}
.dark-theme .rdrNextPrevButton {
  background-color: var(--black130);
}
.dark-theme .rdrNextPrevButton:hover {
  background-color: var(--black120);
}
.dark-theme .rdrPprevButton i {
  border-color: transparent var(--black70) transparent transparent;
}
.dark-theme .rdrNextButton i {
  border-color: transparent transparent transparent var(--black70);
}

.dark-theme .rdrDateDisplayWrapper {
  background-color: var(--black15);
}
.dark-theme .rdrDateInput {
  background-color: var(--black130);
}
.dark-theme .rdrDateDisplayItemActive {
  border-color: var(--black70);
}
.dark-theme .rdrDateDisplayItem input {
  color: var(--black10) !important;
}
.dark-theme .rdrInRange {
  background: rgba(30, 150, 242, 0.25) !important;
}
.dark-theme .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.dark-theme .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.dark-theme .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.dark-theme .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: white !important;
}
