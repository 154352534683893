:root {
  --input-background: white;
  --dropdown-background: #f0f4f8;
  --dropdown-background-selected: #e2e6ea;
  --dropdown-option-color: #4a4a69;
  --dropdown-multiselect-bg: #e7e6e5;
  --input-field-hover-box-shadow: 0px 0px 3px #24d5b8;
}

input:autofill {
  background: var(--input-background);
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.input-error-label {
  padding-top: 4px;
}

.custom-radio .custom-control-label::before {
  top: 1px;
}
.form-group {
  margin-bottom: 0px;
}

.input-title {
  margin-bottom: 6px !important;
}

/* RICHTEXT AREA POPOVER */
.popover-head-primary .popover-header {
  background: var(--primary-info-default) !important;
}

.popover-head-primary.bs-popover-bottom .popover-arrow::after,
.popover-head-primary.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
  border-bottom-color: var(--primary-info-default) !important;
}

.popover-head-primary .popover-header {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: capitalize;
}

.popover-body {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-align: left;
}

.component-additional-label {
  margin-top: 3px;
  margin-bottom: 0;
}

/* CITY SEARCH*/
.city-search-select {
  flex-grow: 1;
}

.cityselect__option--is-focused {
  background-color: var(--dropdown-background) !important;
  color: var(--dropdown-option-color) !important;
}

.cityselect__option--is-selected {
  background-color: var(--dropdown-background-selected) !important;
  color: var(--dropdown-option-color) !important;
}

.cityselect__menu {
  width: calc(100% + 24px) !important;
  border-radius: 8px !important;
}

.has-city-search-flag .cityselect__menu {
  width: calc(100% + 64px) !important;
}

/* DROPDOWN */
.formselect__menu {
  border-radius: 8px !important;
}

.formselect__option--is-focused {
  background-color: var(--dropdown-background) !important;
  color: var(--dropdown-option-color) !important;
}

.formselect__option--is-selected {
  background-color: var(--dropdown-background-selected) !important;
  color: var(--dropdown-option-color) !important;
}

.formselect__multi-value {
  background: var(--dropdown-multiselect-bg) !important;
}

/* DATE PICKER */
.react-datepicker__day:hover {
  color: white !important;
}
.react-datepicker__day--disabled {
  color: var(--black10) !important;
  cursor: disabled;
}
.react-datepicker__day--disabled:hover {
  background-color: var(--black10) !important; 
  color: white !important;
}
.react-datepicker__day--selected {
  color: white !important;
}